import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useApi from '../../../../common/hooks/useApi/useApi';
import './VehicleShow.scss';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import Ribbon from '../../../../components/Ribbon/Ribbon';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import Message from '../../../../common/layout/Message/Message';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import FormSection from '../../../../components/FormSection/FormSection';
import FieldList from '../../../../components/FieldList/FieldList';
import FormField from '../../../../components/FormField/FormField';
import ValueInput from '../../../../common/input/ValueInput/ValueInput';
import Vehicle from '../../../../api/controllers/Movement/Vehicle/Vehicle';
import NoPage from '../../../NoPage/NoPage';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import { labelForEnum } from '../../../../utils/EnumHelper/EnumHelper';
import CheckPermit from '../../../../common/CheckPermit/CheckPermit';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import NumberHelper from '../../../../utils/NumberHelper/NumberHelper';
import { useNavigate, useParams } from 'react-router';
import ShowField from '../../../../components/ShowField/ShowField';
import { vehicleTypes } from '../../../../strings/enums';

const VehicleShow = ({ permissions }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, dataLoading } = useApi({
    call: () => Vehicle.show(id),
    handleError: () => setNotFound(true),
  });
  const [error, setError] = useState();
  const [open, setOpen] = useState();
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  document.title = `${data?.reference}`;

  const handleDelete = () => {
    setLoading(true);
    Vehicle.destroy(id).then(
      () => {
        setLoading(false);
        setOpen();
        navigate({ pathname: '/vehicles', state: { detail: 'Vehicle Deleted' } });
      },
      json => {
        setLoading(false);
        setError({ ...formatError(json), errorTitle: 'Delete Failed!' });
        setOpen();
      },
    );
  };

  const showOptionalElements = () => (
    <>
      <ShowField value={data?.manualSelectionOnly} label={'Manual Selection Only'} />
      <ShowField value={data?.usesKilometres} label={'Kilometres'} />
      <ShowField value={data?.archived} label={'Archived'} />
    </>
  );

  if (notFound) return <NoPage />;
  if (dataLoading) return <LoadingSpinner />;
  if (!data) return null;

  return (
    <div className={'vehicle-show'}>
      <ConfirmationModal
        question={'Are you sure you want to delete this vehicle?'}
        subText={data?.reference}
        confirmText={'Delete'}
        isOpen={!!open}
        handleConfirm={handleDelete}
        handleCancel={() => setOpen()}
        loading={loading}
      />
      <Breadcrumbs>
        <Link to={'/vehicles'}>Vehicles</Link>
        <Link to={`/vehicles/${id}`}>{data?.reference || id}</Link>
      </Breadcrumbs>
      <Ribbon>
        <CheckPermit type={'movementsVehicles'} action={'update'} permissions={permissions}>
          <IconButton
            text={'Edit Vehicle'}
            icon={faPen}
            onClick={() => navigate(`/vehicles/${id}/edit`)}
            className={'edit'}
          />
        </CheckPermit>
        <CheckPermit type={'movementsVehicles'} action={'destroy'} permissions={permissions}>
          <IconButton
            text={'Delete Vehicle'}
            icon={faTrash}
            className={'destructive'}
            onClick={() => setOpen(true)}
          />
        </CheckPermit>
      </Ribbon>
      <div className={'page-content'}>
        <Message text={error} type={'error'} visible={!!error} onClick={() => setError()} />
        <h1>{`${data?.reference || id}`}</h1>
        <TabGroup labels={['General']}>
          <div className={'tab-content'}>
            <FormSection title={'Details'} optionalElement={showOptionalElements()}>
              <FieldList>
                <FormField label={'Reference'} className={'short'}>
                  <input value={data.reference || ''} readOnly />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label={'Type'} className={'short'}>
                  <input value={labelForEnum(vehicleTypes, data.kind)} readOnly />
                </FormField>
                <FormField label={'Driver'} className={'short'}>
                  <input
                    value={data.driver ? `${data.driver.firstName} ${data.driver.lastName}` : ''}
                    readOnly
                  />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label={'Miles-Per-Gallon'} className={'short'}>
                  <input value={data.milesPerGallon} readOnly />
                </FormField>
                <FormField label={'Base Cost'} className={'short'}>
                  <ValueInput
                    value={data.baseCost ? NumberHelper.formatNumToDecPlaces(data.baseCost, 2) : ''}
                    unit={'£'}
                    readOnly
                    reverse
                  />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label={'Capacity'} className={'short'}>
                  <ValueInput value={data.weightLimitKg || ''} unit={'KG'} readOnly />
                </FormField>
                <FormField label={'Dimensions (L/W/H)'} className={'short'}>
                  <div className={'group'}>
                    <ValueInput value={data.lengthCm || ''} unit={'CM'} readOnly />
                    <ValueInput value={data.widthCm || ''} unit={'CM'} readOnly />
                    <ValueInput value={data.heightCm || ''} unit={'CM'} readOnly />
                  </div>
                </FormField>
              </FieldList>
            </FormSection>
          </div>
        </TabGroup>
      </div>
    </div>
  );
};

VehicleShow.propTypes = {
  permissions: PropTypes.object,
};

export default VehicleShow;
